import React from "react"
import SEO from "../components/seo"
import DefaultLayout from "../layouts/defaultLayout"

const ComingSoon = () => {
  return (
    <DefaultLayout>
      <SEO title="Coming Soon" />
      <h1
        style={{
          textAlign: "center",
          fontSize: "1.5rem",
          marginBottom: "2rem",
        }}
      >
        Coming soon...
      </h1>
      <p style={{ textAlign: "center" }}>
        This page is currently undergoing maintenance and will be up and running
        in the coming days
      </p>
    </DefaultLayout>
  )
}

export default ComingSoon
